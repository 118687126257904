import AppRouter from "./v3/routes/AppRoutes";
import { Navbar } from "./v3/routes/Navbar";
//import AppRouter from "./routes/AppRoutes";
//import { Navbar } from "./routes/Navbar";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SessionContextProvider } from "./contexts/SessionContext";
import { Toaster } from 'react-hot-toast';
import { InMaintenance } from "./components/pages/inMaintenance/InMaintenance";
import { MigrationGupy } from "./components/pages/migrationGupy/migrationGupy";

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SessionContextProvider>
        <div className="min-h-full">
          <MigrationGupy />

          {/* <div className="bg-gray-800">
            <Navbar />
          </div>
          <div style={{ paddingTop: "64px" }}>
            <Toaster />
            <AppRouter />
          </div> */}

        </div>
      </SessionContextProvider>
    </QueryClientProvider>
  );
}

export default App;
