import { CheckCircleIcon } from "@heroicons/react/outline"

export const MigrationGupy = () => {

    const benefits = [
        'Salarios competitivos',
        'Horarios de trabajo flexibles',
        'Desarrollo profesional',
        'Crecimiento',
        'Beneficios para ti y tu familia',
        'Descuentos exclusivos para colaboradores',
    ]

    return (
        <div className="bg-dkt-brand-blue min-h-screen flex items-center py-24 sm:py-32">
            <div className="relative isolate w-full">
                <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-white/5 px-6 py-16 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
                        <img
                            alt=""
                            src="https://contents.mediadecathlon.com/p2557952/k$3b22e41dc652a50ba0a958c234e01783/defaut.jpg?format=auto"
                            className="h-96 w-full flex-none rounded-2xl object-cover shadow-xl lg:aspect-square lg:h-auto lg:max-w-sm"
                        />
                        <div className="w-full flex-auto">
                            <h2 className="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">
                            ¡Atención deportistas!
                            </h2>
                            <p className="mt-6 text-pretty text-lg/8 text-gray-300">
                            Estamos actualizando nuestro portal de empleo para brindarte una experiencia aún mejor. Si te apasiona el deporte y quieres formar parte de nuestro equipo, te invitamos a crear tu perfil en nuestro nuevo enlace: 
                            </p>
                            <div className="mt-10 flex justify-center">
                                <a href="https://trabajaconnosotrosdecathlonco.gupy.io/" className="text-sm/6 font-semibold text-dkt-green-01 text-center">
                                    Ingresa al nuevo portal <span aria-hidden="true">&rarr;</span>
                                </a>
                            </div>
                            <ul role="list" className="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base/7 text-white sm:grid-cols-2">
                                {benefits.map((benefit) => (
                                    <li key={benefit} className="flex gap-x-3">
                                        <CheckCircleIcon aria-hidden="true" className="h-7 w-5 flex-none" />
                                        {benefit}
                                    </li>
                                ))}
                            </ul>
                            
                        </div>
                    </div>
                </div>
                <div
                    aria-hidden="true"
                    className="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
                >
                    <div
                        style={{
                            clipPath:
                                'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
                        }}
                        className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
                    />
                </div>
            </div>
        </div>
    )
}